.app__header {
  background-image: url("../../assets/dsc04399.jpg");
  /*background-image: url("C:\Users\kowal\Downloads\project_modern_ui_ux_restaurant-main\project_modern_ui_ux_restaurant-main\src\assets\dsc04399.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--color-black);

}

.app__header-h1 {
  font-family: var(--font-base);
  color: var(--color-black);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 117px;
  font-size: 90px;
}

.app__header-img img {
  width: 80%;
}

.app__header-logo {
  max-width: 100%;
  height: auto;
  justify-content: center;
}

@media screen and (min-width: 2000px) {
  .app__header-h1 {
    font-size: 180px;
    line-height: 210px;
  }
}

@media screen and (max-width: 1150px) {
  .app__header-h1 {
    font-size: 90px;
  }
}

@media screen and (max-width: 650px) {
  .app__header-h1 {
    font-size: 70px;
    line-height: 87px;
  }
}

@media screen and (max-width: 450px) {
  .app__header-h1 {
    font-size: 50px;
    line-height: 70px;
  }
}
